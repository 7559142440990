.container{
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  height: 70vh;

  div.Header {
    font-size: 60px;
    font-weight: 800;
    color: var(--mainWhite);
    padding-top: 30px;
    padding-bottom: 20px;
  }

  div.Contacts {
    width: 100vw;
    max-width: 800px;
    background-color: var(--mainWhite);
    padding: 20px 150px 20px 150px;
    border-radius: var(--brdRadius);
    font-size: 20px;
    overflow: auto;

    div.ContactsName {
      font-size: 30px;
    }

    div.ContactsPhone {
      font-size: 30px;

      a {
        color: inherit;
        text-decoration: inherit;
      }
    }

    div.ContactsDesc {
      font-size: 30px;
    }

    div.ContactsEmail {
      font-size: 30px;

      a {
        color: inherit;
        text-decoration: inherit;
      }
    }
  }
}

.Contacts> * {
  margin: 3px;
  color: var(--black);
  text-align: center;
}


@media (max-width: 850px){
  div.container{
    div.Header {
      font-size: 40px;
      font-weight: 600;
      padding-top: 0px;
    }

    div.Contacts {
      width: 100vw;
      background-color: var(--mainWhite);
      /* text-align:justify; */
      /* align-content: center; */
      padding: 10px 25px 10px 25px;
      font-size: 14px;
  
      overflow: auto;
  
      div.ContactsName {
        font-size: 20px;
        font-weight: 500;
      }
  
      div.ContactsPhone {
        font-size: 18px;
  
        a {
          color: inherit;
          text-decoration: inherit;
        }
      }
  
      div.ContactsDesc {
        font-size: 20px;
      }
  
      div.ContactsEmail {
        font-size: 25px;
  
        a {
          color: inherit;
          text-decoration: inherit;
        }
      }
    }
  }

}

/* @media screen and (max-width: 600px) {
  div.Contacts {
    width: 90vw;
    display: flex;
    flex-flow: column-reverse wrap;
  }
} */