div.container {
  align-self: flex-start;
  flex: 1;
  display: flex;
  /* flex-flow: column; */
  justify-self: stretch;
  height: 70vh;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;

  div.Header {
    font-size: 60px;
    font-weight: 800;
    color: var(--mainWhite);
    padding-top: 30px;
    padding-bottom: 20px;
  }

  div.About {
    max-width: 1000px;
    background-color: var(--mainWhite);
    white-space: pre-line;
    text-align: center;
    padding: 30px;
    border-radius: var(--brdRadius);
    font-size: 20px;
    overflow: auto;
  }
}

@media (max-width: 850px){
  div.container{
    div.Header {
      font-size: 40px;
      font-weight: 600;
      padding-top: 0px;
    }

    div.About{
      width: 100vw;
      font-size: 17px;
      word-wrap: break-word;
    }
  }
}

/* @media screen and (max-width: 600px) {
  div.About {
    width: 90vw;
    display: flex;
    flex-flow: column-reverse wrap;
  }
} */