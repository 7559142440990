*, *::before, *::after{
  font-family: 'Heebo', sans-serif;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  /* min-height: 100%;
  max-height: 100%; */
  /* background-color: #000000; */
  
  font-family: 'Heebo', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}