div.Container{
    text-align: center;
    margin: 0;
    padding: 0;
    position: relative;

    div.CancleXBtn{
        position: absolute;
        left: 0;
        text-align: left;
        color: #808080;
        span{
            font-size: 22px;
        }

        span:hover{
            cursor: pointer;
        }
    }
    
    div.TextContnet{
        margin-top: 5px;
        div.SadImg{

        }

        div.Title{
            span{
                font-weight: 550;
                font-size: 23px;
            }
        }

        div.ThreeRowText{
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            color: #808080;
            font-size: 18px;
            font-weight: 200;
        }
    }
    
    div.ContinueBtn{
        margin-top: 5px;
        display: flex;
        justify-content: center;

        p:nth-child(1){
            border: 1px solid #00000029;
            border-radius: 20px;
            width: max-content;
            padding: 5px;
            padding-left: 20px;
            padding-right: 20px;
        }

        p:hover{
            cursor: pointer;
        }
    }
}