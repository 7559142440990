.container{
  align-self: center;
  margin: auto;
  margin-top: 5%;
  /* width: 100vw; */
}
.TopDiv {
    text-align: center;
    /* display: flex; */
    /* flex-flow: column nowrap; */
    /* justify-content: space-around; */
    margin: 5px;
}
.Desc {
  color: var(--mainWhite);
  opacity: .9;
  font-size: 1.5em;
  margin-bottom: 20px;
  /* display: flex; */
  /* flex-flow: column nowrap; */
}
.btn{
  opacity: .95;
  border-radius: 15px;
  padding: 10px 0 10px 0;
  background-color: transparent;
  cursor: pointer;
}
.btnDesc{
  padding: 5px 15px 5px 15px;
  color: var(--mainWhite);
  font-size: 1.3em;
  margin-bottom: 10px;
}
.imgBasket {
  width: 80px;
}

@media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  }
  