div.ModalContainer{
    div.CloseBtn{
        text-align: left;
        color: #808080;
        font-weight: 700;
        font-size:23px;
        
        span:hover{
            cursor: pointer;
        }
    }
    

    div.Body{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* gap: 30px; */
        /* margin-top: 15px; */

        div.ModalTitle{
            margin-top: 30px;
            text-align: center;
            color: #000;
            font-size: 25px;
            font-weight: 500;
        }
    }
}
