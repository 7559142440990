div.ModalContainer{
    width: 100%;
    height: 100%;
    form{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        div.CloseBtn{
            /* border: 1px solid red; */
            margin-right: auto;
            color: #808080;
            font-weight: 800;
            font-size:33px;
            width: max-content;
            cursor: pointer;

        }

        div.CloseBtn:hover{
            opacity: .8;
        }

        div.BodyContent{
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: .2rem;

            label{
                font-size: 27px;
                font-weight: 500;
            }
            input{
                width: 400px;
                height: 50px;
                font-size: 30px;
                background-color: #e7e7e7;
                padding: .5rem;
                text-align: center;
                border-radius: 15px;
                border: unset;
            }
        }
    }

    div.SendButton{
        /* border: 1px solid red; */
        width: 100%;
        text-align: center;

        button{
            font-size: 30px;
            border-radius: 25px;
            background-color: unset;
            cursor: pointer;
            outline: none;
            width: 40%;
        }

        button:hover{
            opacity: .8;
        }
    }
}


@media (max-width: 600px){
    div.ModalContainer{
        form{
            div.BodyContent{
                label{
                    font-size: 25px;
                    font-weight: 500;
                }
                input{
                    width: 250px;
                    height: 40px;
                }
            }
        
            div.SendButton{
                button{
                    font-size: 25px;
                }
            }
        }
    }
}
