div.Container{
    display: flex;
    width: 100%;
    white-space: nowrap;

    div.RightDiv{
        /* border: 3px solid red; */
        div.Image{
            cursor: pointer;
            width: 100%;
            height: 100%;
            img{
                max-width: calc(.2vw + 200px);
                max-height: calc(.2vw + 65px);
            }
        }
    }
    div.CenterDiv{
        /* border: 3px solid blue; */
        width: 100%;
        cursor: default;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        div.Name{
            font-size: calc(.2vw + 11px);
            font-weight: 600;
        }
        
        div.CenterBottomDiv{
            font-size: calc(.2vw + 10px);

            div.ItemCost{
                font-weight: 600;
            }

            div.Comment{
                overflow: hidden;
                text-overflow: ellipsis;
                text-decoration: underline;
                color: #999;
            }
        }
    }

    div.CenterDiv > * + *{
        margin-top: 15px;
    }

    div.LeftDiv{
        /* border: 3px solid orange; */
        cursor: default;
        text-align: right;
        width: 40%;

        div.Amount{
            color: #888;
            font-size: calc(.2vw + 10px);

        }
        div.TotalCost{
            font-size: calc(.2vw + 13px);
            color: #000;
            font-weight: 600;
        }
    }
}


div.Container > * + *{
    margin-right: 15px;
}