.container {
  /* border: 1px solid red; */
  display: flex;
  flex-flow: column nowrap;
  /* justify-content: center; */
  align-items: center;
  /* height: 100vh; */
  overflow: auto;
  margin-bottom: 2rem;
  max-height: 45rem;
}
.Header {
  /* border: 1px solid red; */
  text-align: center;
  color: var(--mainWhite);
  font-size: 2em;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div{
    flex: 1;
  }
}

@media screen and (max-width: 600px) {
    div.container {
      /* width: 90vw; */
      width: 100%;
      display: flex;
      flex-flow: column nowrap;

    }
  }
  