.container{
  padding: 0;
  margin: 0;

  align-self: center;

  .TopDiv {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin: 15px;

    .day {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      font-size: 24px;

      .dayName {
        justify-self: flex-start;
      }
      .dayHours {
        justify-self: flex-end;
      }
    }
  }
}


@media (max-width: 850px){
  .container{
    .TopDiv {
      .day {
        font-size: 18px;
      }
    }
  }
}
/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  } */
