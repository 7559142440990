div.ModalContainer{
   padding: 5px;

    div.CloseBtn{
        text-align: left;
        color: #808080;
        margin-left: 8px;
        font-weight: 700;
        font-size: 25px;

        /* cursor: pointer; */
        span:hover{
            cursor: pointer;
        }
    }
    

    div.Body{
        display: flex;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        /* gap: 10px; */
        /* margin-top: 15px; */

        div.ModalTitle{
            /* color: #808080; */
            margin-top: 30px;
            color: #000;
            font-size: 25px;
            font-weight: 400;
            text-align: center;
            /* display: flex; */
            /* flex-direction: column; */
            /* justify-content: center; */
            /* align-items: center; */
        }

        div.RowText{
            margin-top: 15px;
            color: #808080;
            font-size: 19px;
            font-weight: 500;
            text-align: center;
        }
      
    }
    button{
        margin-top: 18px;
        font-family: Heebo;
        width:185px;
        height:45px;
        border-radius: 20px;
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        border-radius: 50px;
        outline: none; 
        background-color: unset;
        cursor: pointer;
    }

}
