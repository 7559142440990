div.container {
  align-self: center;
  /* height: 100%; */
  /* height: 100vh; */

  div.AppBody {
    /* height: 100%; */
  }
}

/* @media screen and (max-width: 600px) {
    div.AppBody {
      width: 100vw;
      height: 100%;
      display: flex;
      flex-flow: column-reverse wrap;
    }
  } */
