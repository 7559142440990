div.ModalContainer{
    padding: 5px;
    width: 100%;

    div.CloseBtn{
        text-align: right;
        color: #808080;
        font-weight: 700;
        font-size:23px;
        
        span:hover{
            cursor: pointer;
        }
    }
    
    div.Body{
        height: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        div.BodyContent{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            color: #000;
            font-size: 17px;
            
            :nth-child(1){
                font-size: 22px;
                color: #000;
            }
        }

        div.BikeImage{
            margin: auto;
            margin-top: 10px;
            margin-bottom: 10px;
            width: 28%;
        }

        div.BodyButtons{
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 12px;

            button{
                font-family: 'Heebo', sans-serif;
                border: 1px solid #000;
                cursor: pointer;
                border-radius: 30px;
                width: 120px;
                height: 40px;
                font-size: 16px;
                background-color: unset;
            }
            :nth-child(1){
                font-size: 20px;
            }

            button:hover{
                opacity: .9;
            }
        }

        div.BodyButtons > * + *{
            margin-right: 20px;
        }
    }
}
