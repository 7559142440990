div.Container{
    margin-right: 10px;
    color: #FFF;
    div.InputField{
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        border: 2px solid #FFF;
        border-radius: 20px;
        padding: 6px;
        
        height: 30px; /*ADDED!*/
        overflow: hidden; /*ADDED!*/

        transition: width .6s;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #FFFFFFAF;
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #FFFFFFAF;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: #FFFFFFAF;
      }
}