div.Container{
    /* border: 1px solid red; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 70vh; */
    /* min-height: 100%; */

    /* div.Title{
        margin: 15px;
        color: #FFFF;
        font-size: 25px;
    } */
    margin-top: 10px;
    z-index: 1;

    
    div.BtnGroup{
        /* border: 1px solid red; */
        height: 100%;
        max-width: 340px;
        margin: auto;
       

        div.OptionsBtns{
            display: flex;
            justify-content: center;
            /* margin-right: 15px; */
            button{
                /* margin-left: 15px; */
                width: 100%;
                min-height: 30px;
                font-size: 15px;
                outline: none;
                /* color: #FFFF; */
                border: solid;
                border-width: 1px;
                /* border-radius: 3px; */
                font-family: 'Heebo', sans-serif;
                text-decoration: none;
                .Tooltip{
                    max-width: 400px !important;
                    text-align: right;
                    white-space: pre-wrap !important;
                }
            }
            button:hover{
                opacity: .9;
            }
            
        }
        div.OptionBtns > * + *{
            margin-right: 3%;
        }

    }
}



  
@media (max-width: 850px){
    div.Container {
        width: 100%;

        div.BtnGroup{
            width: 90%;
            max-width: inherit;
        }

        div.Title{
            font-size: 15px;
        }

        div.UserChoosing{
            /* form{
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                align-items: center;

                input, select, textarea{
                    width: 260px;
                }

                .Btns{
        
                    button{
                        margin-top: 10px;
                        width: 250px;
                        height: 30px;
                        font-size: 19px;
                    }
                    
                    button:nth-child(2){
                        width: 250px;
                        height: 30px;
                    }
                } */
        }
    }
}
