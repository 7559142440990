div.Container{
    display: flex;
    justify-conetnt: center;
    align-items: center;
    flex-direction: column;

    div.Title{
        font-size: 25px;
        font-weight: 700;
    }

    div.Body{
        /* margin-top: 15px; */
        display: flex;
        justify-conetnt: center;
        align-items: center;
        flex-direction: column;

        span{
            color: #FF0000;
            font-weight: 400;
            
        }
    }
    
    div.Button{
        margin-top: 25px;
        border: 1px solid #000000;
        /* padding: 5px; */
        padding-left: 40px;
        padding-right: 40px;
        border-radius: 20px;
    }

    div.Button:hover{
        cursor: pointer;
        color: #FFFFFF;
        background-color: #000000;
    }

}