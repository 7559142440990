@media (min-width: 601px){
  div.Container{
    justify-self: right;
    flex-flow: column nowrap;
    margin: 0 0 5px 0;
    border-radius: var(--brdRadius);
    background-color: var(--mainColor);
      
    div.TopDiv {
      min-height: 30px;
      flex-flow: row wrap;
      color: var(--mainWhite);
      padding: 5px;
    }
    
  }
}

@media (max-width: 600px) {
    div.Container{
      
      border-radius: 10px;
      overflow: hidden;
      width: 98%;
      margin-left: auto;
      margin-bottom: 10px;
      position: top;
      background-color: var(--mainColor);
      color: var(--mainWhite);

      div.TopDiv{
      }
      
    }
  }