div.container {
  align-self: flex-start;
  flex: 1;
  display: flex;
  /* flex-flow: column; */
  justify-self: stretch;
  height: 100%;
  width: 80vw;
  margin: auto;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 75px;

  div.Header {
    text-align: center;
    font-size: 36px;
    font-weight: 800;
    color: var(--mainWhite);
  }

  div.Form {
    /* border: 1px solid red; */
    width: 500px;
    padding: 10px;
    overflow: auto;
    z-index: 1;
    div.FormLine {
      width: 100%;

      div.FormLineOne {
        width: 100%;
        padding: 3px;
      }

      div.FormLineTwo {
        width: 50%;
        padding: 3px;
        display: inline-block;
      }

      .FormInput {
        width: 100%;
        line-height: 30px;
        padding: 5px;
        padding-left: 15px;
        padding-right: 15px;
        outline: unset;
        border-radius: 10px;
        font-size: 24px;
        font-family: inherit;
        font-weight: 300;

        &.FormInputWrong {
          border: 2px solid #eb0028;
        }
      }
    }
  }

  div.RegisterButton {
    /* border: 1px solid rgb(0, 0, 0, 0.3); */
    font-size: 30px;
    width: 570px;
    margin-top: 20px;
    text-align: center;
    padding: 8px;
    color: var(--mainWhite);
    border-radius: 50px;
    cursor: pointer;

  }
  
  div.RegisterButton:hover{
    opacity: .9;
  }

  div.ModalContainer{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 850px){
  div.container {
    div.Header {
      font-size: 26px;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    div.Form {
      width: 120%;
      z-index: 0;

      div.FormLine {
        width: 100%;
  
        div.FormLineOne {
        }
  
        div.FormLineTwo {
        }

        input.FormInput {
          font-size: 20px;
          padding: 5px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    div.RegisterButton {
      z-index: 0;
      font-size: 30px;
      width: 80vw;
      margin-top: 40px;
      padding: 4px;
    }
  }
}